import { Fab } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { withTransientProps } from '@/helpers/with-transient-props';
import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';

export type StyledTooltipContainerProps = {
  tooltipLink: string;
};

export const StyledTooltipContainer = styled(
  ({ tooltipLink, ...props }: StyledTooltipContainerProps) => (
    <div {...props} />
  ),
)(({ theme, tooltipLink }) => ({
  '& a': {
    color: theme.palette.common.white,
    borderBottom: `0.2rem solid ${theme.palette.common.white}`,
    width: 'unset',
    fontSize: theme.spacing(1.6),
    fontWeight: 'normal',
    textDecoration: 'none',
    paddingTop: theme.spacing(0.7),
  },
  '& p': {
    fontSize: theme.spacing(1.6),
    color: theme.palette.common.white,
    lineHeight: theme.spacing(2),
    wordSpacing: 'normal',
    ...(tooltipLink && {
      marginBottom: theme.spacing(1.6),
    }),
  },
}));
export const StyledIcon = styled(Icon, withTransientProps)<{
  $height: number, $width: number }>(({ theme, $width = 2.5, $height = 2.5 }) => ({
  height: theme.spacing($height),
  width: theme.spacing($width),
}));
export const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: '1.5rem',
    '&:before': {
      color: theme.palette.text.primary,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    textAlign: 'unset', // MUI4 -> MUI5 fix
    background: theme.palette.text.primary,
    color: theme.palette.common.white,
    boxShadow:
      '0 0.2rem 0.4rem rgba(211,211,211,0.2), 0 0.2rem 0.4rem rgba(0,0,0,0.2)',
    width: theme.spacing(23.2),
    padding: theme.spacing(1.6),
    top: theme.spacing(0.4),
  },
  [`& .${tooltipClasses.popper}`]: {
    marginRight: theme.spacing(1),
  },
}));

export const StyledTooltipFab = styled(Fab, withTransientProps)<{
  $height: number, $width: number }>(({ theme, $width = 2.5, $height = 2.5 }) => ({
  background: 'none',
  height: theme.spacing($height),
  width: theme.spacing($width),
  minHeight: 'unset',
  boxShadow: 'none',
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&.MuiFab-root': {
    boxShadow: 'none',
    background: 'none',
    '& svg': {
      fill: theme.palette.common.black,
    },
    '& .MuiFab-label': {
      width: 'unset',
    },
    '&:focus': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
      outline: `${theme.spacing(0.3)} solid ${theme.palette.text.primary}`,
      outlineOffset: theme.spacing(0.1),
    },
  },
  border: 'none',
}));
